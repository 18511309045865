import { find } from 'lodash'
import Router from 'next/router'
import { useEffect, useRef } from 'react'
import useSWR, { useSWRConfig } from 'swr'
import { cache } from 'swr/_internal'
import { useAnalytics } from '../components/context/AnalyticsProvider'
import useSocket from './api/useSocket'
import createFormData from './createFormData'
import fetchJson from './fetchJson'
import useMutators from './useMutators'

export default function useUser({
  redirectTo = false,
  redirectIfFound = false,
  slug = null,

  // DEPRECATED: 13-08-2021
  id = null,
} = {}) {
  const { mutate } = useSWRConfig()
  const { updateUser: updateAnalyticsUser, trackData } = useAnalytics()

  if (id != null && process.env.NODE_ENV === 'development') {
    console.warn(
      'DEPRECATED: use of the `id` parameter for useUser is deprecated. Use `slug` instead'
    )
  }
  const urlIdentifier = slug || id
  const endpoint = urlIdentifier
    ? `/api/users/${urlIdentifier}.json`
    : '/api/user.json'
  const {
    error,
    data,
    mutate: mutateUser,
    isValidating,
    isLoading,
  } = useSWR(endpoint)
  const user = data?.user
  const loggedOut = !user || (error && error.status === 401)
  const shouldRedirect = !isLoading || redirectTo?.startsWith?.('/invite')

  const isCurrentUser = urlIdentifier == null
  useEffect(() => {
    if (!isCurrentUser) {
      return
    }
    
    updateAnalyticsUser(user)
  }, [user?.id, user?.analytics_enabled, isCurrentUser])

  const handleRedirect = () => {
    if (
      // If redirectTo is set, redirect if the user was not found.
      (redirectTo && !redirectIfFound && loggedOut) ||
      // If redirectIfFound is also set, redirect if the user was found
      (redirectIfFound && !loggedOut)
    ) {
      Router.push(redirectTo)
    }
  }

  useEffect(() => {
    // if no redirect needed, just return (example: already on /dashboard)
    // if user data not yet there (fetch in progress, logged in or not) then don't do anything yet
    if (!redirectTo || isLoading || !shouldRedirect) return

    handleRedirect()
  }, [
    isValidating,
    loggedOut,
    redirectIfFound,
    redirectTo,
    isLoading,
    shouldRedirect,
  ])

  let mutator = mutateUser

  if (urlIdentifier == null) {
    mutator = function (data) {
      mutateUser(data)
      mutate(`/api/users/${data?.user?.slug}.json`, data)

      if (data?.user == null) {
        cache.clear()
      }
    }
  }

  const receivedUnreadCountRef = useRef()

  useEffect(() => {
    receivedUnreadCountRef.current = (updateData) => {
      mutateUser(
        (data) => ({
          user: { ...data?.user, ...updateData },
        }),
        false
      )
    }
  }, [mutateUser])

  useSocket(
    `user-unread-count-${user?.id}`,
    {
      channel: 'UserChannel',
      id: user?.id,
    },
    {
      received: (msg) => {
        if (msg?.event === 'unread_notifications_count') {
          receivedUnreadCountRef.current?.({
            unread_notifications_count: msg?.data?.count,
          })
        }

        if (msg?.event === 'unread_messages_count') {
          receivedUnreadCountRef.current?.({
            unread_messages_count: msg?.data?.count,
          })
        }
      },
    }
  )

  const api = useMutators(mutator, {
    login,
    signup,
    logout,
    updateUser,
    updateUserFormData,
  })

  return {
    user,
    error,
    mutateUser,
    isValidating,
    markNotificationsAsRead: markNotificationsAsRead.bind(this, mutateUser),
    ...api,
    login: async (email, password, mfaToken) => {
      const result = await api.login(email, password, mfaToken)
      trackData('login')
      return result
    },
    passwordReset,
    updatePasswordReset,
    deleteUser: deleteUser.bind(this, mutateUser),
    loggedOut,
    isAuthed: !loggedOut,
    isGroupManager(groupId) {
      return find(user?.managed_groups, ['id', groupId])
    },
    isBadgeManager(badgeId) {
      return find(user?.managed_badges, ['id', badgeId])
    },
  }
}

export async function login(email, password, mfaToken) {
  let body = { user: { email, password } }
  if (mfaToken !== null) {
    body = { user: { email, password, mfa_token: mfaToken } }
  }
  const result = await fetchJson('/api/login.json', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
  })

  return result
}

export async function signup(
  name,
  email,
  password,
  allowMarketing,
  acceptedTerms
) {
  const body = {
    user: {
      name,
      email,
      password,
      password_confirmation: password,
      allow_marketing: allowMarketing,
      accepted_terms: acceptedTerms,
    },
  }

  return await fetchJson('/api/users.json', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
  })
}

export async function logout() {
  return await fetchJson('/api/logout', {
    method: 'POST',
  })
}

export async function passwordReset(email) {
  const body = { email }

  return await fetchJson(`/api/password_resets.json`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
  })
}

export async function updatePasswordReset(
  token,
  password,
  passwordConfirmation
) {
  const body = {
    user: { password, password_confirmation: passwordConfirmation },
  }

  return await fetchJson(`/api/password_resets/${token}.json`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
  })
}

export async function updateUser(user) {
  return await fetchJson(`/api/users/${user.slug}.json`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(user),
  })
}

export async function updateUserFormData(user) {
  const formData = createFormData({ user })

  return await fetchJson(`/api/users/${user.slug}`, {
    method: 'PUT',
    body: formData,
  })
}

export async function markNotificationsAsRead(mutateUser) {
  const body = { read_at: new Date().toISOString() }

  const resp = await fetchJson('/api/notifications.json', {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
  })

  mutateUser(
    (data) => ({
      user: { ...data?.user, unread_notifications_count: resp?.unread },
    }),
    false
  )

  return resp
}

export async function deleteUser(mutateUser, userId) {
  await fetchJson(`/api/users/${userId}`, {
    method: 'DELETE',
  })

  mutateUser(
    () => ({
      user: null,
    }),
    false
  )
}
